.search-map {
    width: 100%;
    height: 310px;
    background: #fff;
}
@media(min-width: @screen-sm-min) {
    .search-map {
        height: 500px;
        border: 5px solid #fff;
    }
}
@media(min-width: @screen-md-min) {
    .search-map {
        height: 580px;
    }
}


.search-map-window {
    max-width: 250px;
    font-family: @font-family-sans-serif;
    font-weight: 400;
    font-size: 13px;
    p {
        margin: 0;
        line-height: 1.6;
    }
    p.search-map-window-merchant {
        font-size: 12px;
    }
    p.search-map-window-name {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 3px;
    }
    p.search-map-window-address {
        margin-bottom: 8px;
    }
    .btn {
        padding: 3px 8px;
    }
}

.search-branch_list {
    .portlet {
        margin-bottom: 15px;
    }
    .media-left {
        > a {
            display: block;
            > .media-object {
                width: 80px;
                height: 80px;
            }
        }
    }
    .search-branch_list-branch-merchant {
        margin: 0 0 3px 0;
        font-size: 12px;
    }
    .search-branch_list-branch-name {
        font-size: 18px;
        margin: 0 0 5px 0;
        font-weight: 600;
    }
    .search-branch_list-branch-address {
        margin: 0 0 8px 0;
    }
}
@media(min-width: @screen-md-min) {
    .search-branch_list {
        .portlet {
            margin-bottom: 30px;
        }
    }
}