.transactions {
    .transactions-list {
        margin-bottom: 0;
        table {
            margin-bottom: 0;
            th {
                &.transactions-list-date {
                    width: 175px;
                }
                &.transactions-list-points {
                    width: 90px;
                }
                &.transactions-list-cta {
                    width: 20px;
                }
            }
            td {
                vertical-align: middle;
                &.transactions-list-date {

                }
            }
        }
    }
    .transactions-transaction {
        dl {
            dt {
                margin-top: 8px;
                &:first-child {
                    margin-top: 0;
                }
            }
            margin-bottom: 0;
        }
    }
}
