.card {
  padding: 40px 20px;
  .card-title {
    font-size: 26px;
    margin-top: 0;
  }
  .card-chart {
    margin-bottom: 10px;
  }
  .card-points-total {
    font-size: 3em;
    font-weight: 600;
    margin-top:30px;
  }
  .btn {font-size: 1.4em;}
  .btn-primary {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  }
  .btn-primary:hover {
    box-shadow: none;
  }
  .btn-default {
    background: none;
    border-width: 2px;
    margin-top: 10px;
  }
  .btn-default:hover {
    box-shadow: none;
  }
  .card-points-required {
    text-align: center;
    margin-top: -30px;
    .points-number { font-size: 1.8em; font-weight: 600; margin:0;}
    .points-label {margin:0;}
  }

   .card-numbers-row {
     margin: 20px auto;
     .card-points-total {
       margin:0 0 5px 0;
     }
   }
}

.branding-header {
  padding: 20px 0;
  .sub-title {font-size: 1.3em;}
  .points-total {
    font-size: 3em;
    font-weight: 600;
    margin-bottom: 0;
  }
}

.event-body {padding: 10px 40px;}


.dashboard-card {
    .card-body {
      background-size: cover;
      background-position: center center;
      .inner {
        padding: 20px;
        .btn-primary {
          background: none;
          border: 1px solid #333;
          color: #333;
          margin: 5px 0;
        }
        .btn-primary:hover {background: #333; color: #FFF}
      }
      .inner.overlay {
        background: rgba(0, 0, 0, 0.5);
        color: #FFF;
        .btn-primary {
          background: none;
          border: 1px solid #FFF;
          color: #FFF;
          .badge {background: #D00; color: #FFF;}
        }
        .btn-primary:hover {background: #FFF; color: #333}
      }
      .logo {
        background: #FFF; width: 83px; height:83px; padding:3px;
        border-radius: 3px; border: 1px solid #CCC;
        img { display:block; }
      }
      .pie-chart {
        width: 83px;
        height: 100px;
        float:right;
        .chart-contain {width: 65px; height: 65px; margin:0 auto;}
        p {
          text-align: center; margin: 0; font-size: 0.8em; padding-top: 5px;
        }
      }

      .points-statement {
        padding: 5px 0;
      }

    }
}

.action-reward-list {
  margin-bottom: 10px;
  .action-reward-item {
    border-bottom: 1px solid #EEE;
    padding: 10px 0;
    p {margin:0;}
    .points-blibble {
      background: #EEE;
      padding: 10px 0;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display:block;
      text-align: center;
      font-size: 0.8em;
      font-weight: bold;
      margin:0 auto;
    }
  }
}

.make-transaction-tabs {
  margin: 20px auto;
  max-width: 600px;
  li {
    font-weight: bold;
    .badge-danger {
      background: #D00;
      color: #FFF;
    }
  }
}

.event-list-date {
  display: block;
  padding: 10px;
  .day {font-size: 2em; margin: 0;}
  .month {font-size: 1.2em; margin: 0; text-transform: uppercase;}
}
.event-list-title {
  color: #333;
}

.event-list-by {
  color: #AAA;
  a {color: #AAA;}
}

.calendar-event {
  padding: 3px 5px;
  font-size: 0.9em;
  display: block;
}

.event-list-item a {
  text-decoration: none;
}

.event-list-item .event-list-by a:hover {
  text-decoration: underline;
}

.modal-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

@names  : dark_green, light_green, yellow, orange, red, dark_red, pink, purple, navy, blue, brown, white, grey, black;
@colours : #0C8117, #95D100, #FFDE37, #FF9E2C, #F6151A, #8D070A, #DB009D, #511F81, #083586, #00C2E0, #5B2C19, #F5F5F5, #B7C5C7, #0F0F0F;
@text_colours : #FFF, #FFF, #333, #FFF, #FFF, #FFF, #FFF, #FFF, #FFF, #FFF, #FFF, #333, #333, #FFF;
@length : length(@colours);

.brand-variation(@names; @colours; @index) when (iscolor(extract(@colours, @index))) and (@index > 0) {
    .brand-variation(@names; @colours; (@index - 1)); // decrement.

  @name  : extract(@names, @index);
  @colour : extract(@colours, @index);
  @text : extract(@text_colours, @index);

  .brand-header-@{name} {
    //border-top: 10px solid @colour;
    .btn-primary {
      background: @colour;
      color: @text;
    }
  }

  .brand-@{name}.header-fixed-top {
    border-bottom: 5px solid @colour;
  }

  .brand-@{name} {
    border-color: @colour;
    .panel-heading {
      background: @colour;
      border-color: @colour;
      color: @text;
      p a {color: @text;}
    }
    .panel-body {
      .btn-primary {
        background: @colour;
        border-color: @colour;
      }
      .btn-primary:hover {
        background: darken(@colour, 10%);
      }
    }
    .action-reward-item {
      .reward-available {
        color: @colour;
      }
      .points-blibble.available {
          background: @colour;
          color: @text;
      }
    }
    .event-list-date {
      background: @colour;
      color: @text;
    }
  }

  .brand-@{name}.calendar-event {
    background: @colour;
    color: @text;
  }

  .brand-@{name}.modal-header {
    background: @colour;
    color: @text;
    .close {
      color: @text;
      opacity: 1;
    }
  }
  .brand-@{name}.modal-body {
    .btn-primary {
      background: @colour;
      color: @text;
      border-color: @colour;
    }
  }

}
.brand-variation(@names; @colours; @length);
