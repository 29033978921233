.transaction {
    .transaction-items {
        .clearfix;
        margin: 0 auto 20px auto;
        max-width: 600px;
        .transaction-items-row {
            .clearfix;
            &:first-child {
                .transaction-item {
                    border-top: solid 1px #cccccc;
                }
            }
        }
        .transaction-item {
            padding: 10px;
            float: left;
            width: 50%;
            background: #ffffff;
            border-bottom: solid 1px #cccccc;
            border-right: solid 1px #cccccc;
            cursor: pointer;
            -webkit-transform-style: preserve-3d;
            -moz-transform-style: preserve-3d;
            transform-style: preserve-3d;
            &:first-child {
                border-left: solid 1px #cccccc;
            }
            &:hover {
                background: #f3f3f3;
            }
            .transaction-item-inner {
                .clearfix;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
            .transaction-item-name {
                color: #333;
                text-align: center;
                font-weight: 600;
                font-size: 13px;
                margin: 0 0 3px 0;
            }
            .transaction-item-points {
                color: #666;
                text-align: center;
                font-size: 12px;
                margin: 0;
            }
            .transaction-item-quantity {
                max-width: 150px;
                margin: 10px auto 5px auto;
                .form-control {
                    text-align: center;
                }
            }
            &.transaction-item-active {
                background: @brand-primary;
                .transaction-item-name, .transaction-item-points {
                    color: #FFF;
                }
            }
        }
    }
    .transaction-summary {
        text-align: center;
        margin: 0 0 20px 0;
        .transaction-summary-points {
            font-size: 150%;
        }
    }
    .transaction-passphrase {
        .form-control {
            text-align: center;
        }
    }
}
