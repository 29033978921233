.merchant_profile {
    .panel {
        margin-bottom: 30px;
    }
    .panel-heading {
        padding-top: 8px;
        padding-bottom: 8px;
        .panel-title {
            font-size: 16px;
        }
        .btn-group {
            float: right;
            margin: -2px 0 0 5px;
        }
    }
    .merchant_profile-points {
        text-align: center;
        .portlet {
            display: inline-block;
            .portlet-body {
                padding: 10px 20px;
                p {
                    margin: 0;
                    text-align: center;
                    .merchant_profile-points-balance {
                        font-size: 130%;
                        color: @brand-primary;
                    }
                }
            }
        }
    }
    .merchant_profile-overview {
        .clearfix;
        .merchant_profile-overview-logo {
            display: block;
            max-width: 75px;
            background: #fff;
            margin: 0 0 10px 15px;
            float: right;
        }
        .merchant_profile-overview-desc {
            > p {
                margin: 0 0 20px 0;
            }
        }
        .merchant_profile-overview-contact {
            margin: 0 0 20px 0;
            > p {
                margin: 5px 0 0 0;
                &:first-child {
                    margin-top: 0;
                }
                strong {
                    display: inline-block;
                    width: 90px;
                    font-weight: 600;
                }
            }
        }
        .merchant_profile-overview-social {
            > p {
                margin: 0;
                line-height: 1.2;
                font-size: 28px;
            }
        }
    }
    .merchant_profile-branches-map {
        .merchant_profile-branches-branchesmap {
            width: 100%;
            height: 300px;
        }
    }
    .merchant_profile-events-list {
        td strong {
            font-weight: 600;
        }
    }
    .merchant_profile-transactions {
        .panel-heading {
            padding: 8px 10px;
            .panel-title {
                margin: 2px 0 0 0;
                font-size: 16px;
            }
            p {
                margin: 1px 0 0 0;
            }
        }
        .merchant_profile-transactions-transaction {
            td {
                vertical-align: middle;
                &.merchant_profile-transactions-transaction-date {
                    width: 100px;
                    font-size: 13px;
                    .text-muted;
                }
                &.merchant_profile-transactions-transaction-points {
                    text-align: right;
                    width: 75px;
                    font-weight: bold;
                }
            }
        }
        .panel-body > .btn-group {
            display: block;
            margin: 0 auto;
            max-width: 250px;
        }
    }
}
