.branch_profile {
    .panel {
        margin-bottom: 30px;
    }
    .panel-heading {
        padding-top: 8px;
        padding-bottom: 8px;
        .panel-title {
            font-size: 16px;
        }
        .btn-group {
            float: right;
            margin: -2px 0 0 5px;
        }
    }
    .branch_profile-points {
        text-align: center;
        .portlet {
            display: inline-block;
            .portlet-body {
                padding: 10px 20px;
                p {
                    margin: 0;
                    text-align: center;
                    .branch_profile-points-balance {
                        font-size: 130%;
                        color: @brand-primary;
                    }
                }
            }
        }
    }
    .branch_profile-overview {
        .clearfix;
        .branch_profile-overview-logo {
            display: block;
            max-width: 75px;
            background: #fff;
            margin: 0 0 10px 15px;
            float: right;
        }
        .branch_profile-overview-desc {
            > p {
                margin: 0 0 20px 0;
            }
        }
        .branch_profile-overview-contact {
            margin: 0 0 20px 0;
            > p {
                margin: 5px 0 0 0;
                &:first-child {
                    margin-top: 0;
                }
                strong {
                    display: inline-block;
                    width: 90px;
                    font-weight: 600;
                }
            }
        }
        .branch_profile-overview-social {
            > p {
                margin: 0;
                line-height: 1.2;
                font-size: 28px;
            }
        }
    }
    .branch_profile-tagrewards {
        .panel-body {
            text-align: center;
            h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
                margin-top:    20px;
                margin-bottom: 15px;

                font-weight:   600;
            }
            > .row > .col-sm-6:first-child {
                h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
                    margin-top: 0;
                }
            }
            dl {
                margin-bottom: 0;
                dt {
                    font-weight: 600;
                    margin-top:  10px;
                    margin-bottom: 1px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
                dd {
                    .text-muted;
                }
            }
        }
    }
    .branch_profile-branches-map {
        .branch_profile-branches-branchesmap {
            width: 100%;
            height: 300px;
        }
    }
    .branch_profile-transactions {
        .panel-heading {
            padding: 8px 10px;
            .panel-title {
                margin: 2px 0 0 0;
                font-size: 16px;
            }
            p {
                margin: 1px 0 0 0;
            }
        }
        .branch_profile-transactions-transaction {
            td {
                vertical-align: middle;
                &.branch_profile-transactions-transaction-date {
                    width: 100px;
                    font-size: 13px;
                    .text-muted;
                }
                &.branch_profile-transactions-transaction-points {
                    text-align: right;
                    width: 75px;
                    font-weight: bold;
                }
            }
        }
        .panel-body > .btn-group {
            display: block;
            margin: 0 auto;
            max-width: 250px;
        }
    }
}

.checkin-strip {
  background: #262262;
  padding: 20px 0;
  color: #FFF;
  h3 {
    font-size: 1.8em;
    margin:0 0 5px 0;
  }
  p {
    color: @loyally-light-blue;
    font-size: 1.2em;
    margin: 0 0 5px 0;
    strong {
      color: @loyally-orange;
    }
  }
  .checkin-icon {margin-top: 30px; float: right;}
}

.branch-nav-strip {
  padding: 20px 0;
  background: #FFFFFF;
  .btn-primary {background: @loyally-blue}
  .btn-default {border-color: @loyally-blue; color: @loyally-blue;}
  .badge-danger {background: @brand-danger; }
}

.hub-list {
  .list-item {
    border-top: 1px solid @loyally-light-blue;
    background: #FFFFFF;
    padding: 20px 0;
    p {color: @loyally-light-blue}
    display: block;
    p.heading {
      color: @loyally-blue;
      font-weight: 600;
      font-size: 1.5em;
    }
    .points-blibble {
      border-radius: 50%;
      width: 70px;
      height: 70px;
      padding: 12px 15px;
      text-align: center;
      display: block;
      color: #FFF;
      background: @loyally-blue;
      .points-total {
        font-size: 1.4em;
        margin:0;
        text-align: center;
      }
      .points-label {
        font-size: 0.8em;
        margin:0;
        text-align: center;
      }
    }
    p {
      strong { color: @loyally-blue; font-size: 1.4em;}
    }
    .reward-available {
      text-align: center;
      .glyphicons {
        font-size: 3em;
      }
    }
  }
  a.list-item:hover {
    background: #f9f9f9;
    p.heading {text-decoration: underline;}
  }

  .list-item.available {border-color: #FFF;}
}

.hub-list-nav {
  .container {
    background: url('/img/hub-list-arrow.png') no-repeat 95% center ;
  }
}

.hub-list-nav.no-arrows {
  .container {
    background: none;
  }
}

.accordion-group {
  background: #FFFFFF;
  .accordion-item {
    border-top: 1px solid @loyally-light-blue;
    padding: 10px;
    .accordion-item-title {
      color: @loyally-blue;
      font-weight: 600px;
      font-size: 1.2em;
      a {color: @loyally-blue;}
    }
    .accordion-item-body {
      color: @loyally-blue;
      font-weight: normal;
    }
  }
}

.points-diagram {position: relative; min-height: 120px;}
.transactions-item {
  p {color: @loyally-light-blue;}
  .item {color: @loyally-blue;}
  border-bottom: 1px solid @loyally-lighter-blue;
  padding-bottom: 10px;
}
.point-circle { position: relative; width: 60px; height: 60px; background: #CCC; border-radius: 50%; text-align: center; padding: 15px 10px; z-index: 10; color: #FFF; border: 4px solid #CCC;}
.point-circle-credit {background: #7ED321}
.point-circle-debit {background: #FAA526}
.point-stalk {position: absolute; width: 4px; height: 100%; background: #CCC; top: 0; left: 28px; z-index: 5}

.strip-white {
    background: #FFF;
    padding-top: 40px;
    padding-bottom: 20px;
    h1 {
      color: @loyally-blue;
      font-weight: 600;
      margin-top:0;
    }
 }

 .strip-white-no-pad {
   background: #FFF;
   h1 {
     color: @loyally-blue;
     font-weight: 600;
   }
 }



.js-branchesmap-map {height: 500px;}
.js-branchesmap {height: 100%}

.uploading-screen {position: fixed; background: rgba(0,0,0,0.8); width: 100%; height:100%;  z-index:9999; top:0;}
.uploading-screen .inner { width: 50%; margin: 200px auto 0 auto; color: #FFF; text-align: center;}
.uploading-screen .inner .title { font-size: 4em;}



@media(min-width: @screen-sm-min) {
    .branch_profile {
        .branch_profile-tagrewards {
            .panel-body {
                text-align: left;
                h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
                    margin-top: 0;
                    padding-bottom: 4px;
                    border-bottom: solid 1px #dddddd;
                }
            }
        }
    }
}
