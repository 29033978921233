.marketing-merchants {
    .panel-heading {
        padding: 8px 10px;
        .panel-title {
            margin: 2px 0 0 0;
            text-align: left;
            font-size: 15px;
        }
    }
    .panel-body {
        padding-top: 5px;
        padding-bottom: 15px;
        .checkbox {
            margin: 10px 0 0 0;
        }
    }
}
.marketing-save {
    text-align: center;
    margin: 5px 0 20px 0;
}