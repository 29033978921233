.dashboard-merchants {
    .panel-heading {
        padding: 8px 10px;
        .panel-title {
            margin: 2px 0 0 0;
            font-size: 15px;
        }
        p {
            margin: 0;
        }
    }
    .dashboard-merchants-merchant-transaction {
        td {
            vertical-align: middle;
            font-size: 90%;
            &.dashboard-merchants-merchant-transaction-date {
                width: 65px;
                font-size: 80%;
                .text-muted;
            }
            &.dashboard-merchants-merchant-transaction-points {
                text-align: right;
                width: 65px;
                font-weight: bold;
            }
        }
    }
}

.nothing-to-show-message {
  margin-top: 50px;
  h3 {
    color: @loyally-blue;
    font-size: 1.8em;
    font-weight: 700;
  }
  p { color: @loyally-light-blue; font-size: 1.2em; }
  .btn-default {
    background: transparent;
    border: 2px solid @loyally-blue;
    color: @loyally-blue;
  }
}

.strip-white .btn-default {
  background: transparent;
  border: 2px solid @loyally-blue;
  color: @loyally-blue;
}

.user-avatar {
  border-radius: 50%;
  border: 5px solid @loyally-lighter-blue;
}

.user-avatar-header {
  background: @loyally-blue;
  position: relative;
  margin-bottom: 40px;
  height: 80px;
  .user-avatar {
    position: absolute;
    left: 50%;
    bottom: -50px;
    margin-left: -52px;
  }
}
