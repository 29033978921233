.portlet {
    margin-bottom: @line-height-computed;
    background-color: @portlet-bg;
    border: 1px solid transparent;
    border-radius: @portlet-border-radius;
    .box-shadow(0 1px 1px rgba(0,0,0,.05));
}
.portlet-header {
    padding: @portlet-header-padding;
    border-bottom: 1px solid transparent;
    &:extend(.clearfix all);
    > h1, > .h1, > h2, > .h2, > h3, > .h3, > h4, > .h4, > h5, > .h5, > h6, > .h6 {
        margin: 0;
    }
}
.portlet-body {
    padding: @portlet-body-padding;
    &:extend(.clearfix all);
}
.portlet-header + .portlet-body {
    padding-top: (@portlet-body-padding / 2);
}