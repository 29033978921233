
.calendar {padding: 20px;}
.calendar .calendar-head {display: none; }
.calendar .calendar-head .col-calendar-1 {font-weight: bold}
.calendar .calendar-head .col-calendar-1 .inner,
.calendar .calendar-body .col-calendar-1 .inner { padding: 15px;}
.calendar .calendar-body .col-calendar-1,
.calendar .calendar-head .col-calendar-1 {border-right: 1px solid #F4F4F4; border-top: 1px solid #F4F4F4;}
.calendar .calendar-body .col-calendar-1 .inner { min-height: 120px;}
.calendar .calendar-head .col-calendar-1 .inner {text-align: center;}

.calendar .row .col-calendar-1:first-child {border-left: 1px solid #F4F4F4;}
.calendar .row:last-child .col-calendar-1 {border-bottom: 1px solid #F4F4F4;}

.calendar-month-controls {padding: 10px 0;}
.calendar-month-controls .pad-top {padding-top: 20px;}

.calendar .calendar-body .col-calendar-1.month-pad .inner {background: #F8F8F8;}

.calendar .calendar-body,
.calendar .calendar-head {background: #FFF; }
.calendar .calendar-body .day {color: #999; display: block; margin-bottom: 5px;}
.calendar .calendar-body .day.current-day {color: #337ab7; font-weight: bold;}

.calendar-month-select .btn {background: none; border: 0;}

.calendar .calendar-body .col-calendar-1.month-pad {display: none;}

.calendar .calendar-body .event-text {display: block; margin-top: 5px;}

.calendar .calendar-body .event {margin-bottom: 10px; display: block;}
.calendar .calendar-body .event:hover {text-decoration: none;}
.calendar .calendar-body .event:hover .event-text { text-decoration: underline;}

.col-calendar-1 {width: 100%;}

.calendar-month-select .btn {padding: 4px 12px;}
.calendar-month-select ul {min-width: 210px;}
.calendar-month-select ul li a {padding: 10px 20px;}

.events-header {text-align:center;}
.events-header .btn-group.pull-right {float: none!important;}
.events-header .calendar-subscribe {clear:both; margin-top: 10px;}

.event-list-date {margin-bottom: 20px;}

@media (min-width: 768px) {

  .events-header {text-align:left;}
  .events-header .btn-group.pull-right {float: right!important;}
  .events-header .calendar-subscribe {clear:none; margin-top: 0;}

    .calendar .calendar-body {overflow: hidden;}
    .col-calendar-1 {width: 14.285%; float: left; margin-bottom: -300px; padding-bottom: 300px;}
    .calendar .calendar-head {display: block; }
    .calendar .calendar-body .col-calendar-1.month-pad {display: block;}

    .event-list-date {margin-bottom: 0;}
}
