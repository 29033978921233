.register-oauth {
    .alert {
        .media-heading {
            .glyphicons {
                top: 1px;
                margin-right: 3px;
            }
        }
        .media-object {
            width: 62px;
        }
        .media-body {
            > p {
                margin-bottom: 0;
            }
        }
    }
}

.welcome-wrapper {
  h1 {font-size: 1.2em; margin: 20px 0;}
  .help-block {color: @loyally-light-blue;}
  p {font-size: 1.2em;}
  p a {color: @loyally-light-blue;}
  .text-standout {color: @loyally-orange;}
  hr {border-color: #4a4693;}
}



.divider-or {position: relative; border-top: 1px solid #DDD; margin-top: 40px; margin-bottom: 40px; clear:both;}
.divider-or span { background: #EEE; border-radius: 50%; position: absolute; left: calc(~'50% - 30px'); top: -30px; display: block; padding: 12px; width: 60px; height: 60px; font-weight: bold; border: 10px solid #FFF; font-size: 0.8em;}
