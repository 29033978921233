//
// Header base
//
.header {
    position:      relative;
    min-height:    @header-height; // Ensure a navbar always shows (e.g., without a .header-brand in collapsed mode)
    margin-bottom: @header-margin-bottom;
    border:        1px solid transparent;

    // Prevent floats from breaking the navbar
    &:extend(.clearfix all);
}

//
// Header uncollapseable
//
// Groups `.header-brand` and `.header-toggle` into a single component for easy styling of responsive aspects.
.header-uncollapseable {
    &:extend(.clearfix all);
    @media (min-width: @grid-float-breakpoint) {
        float: left;
    }
}

// Navbar collapse (body)
//
// Group your navbar content into this for easy collapsing and expanding across
// various device sizes. By default, this content is collapsed when <768px, but
// will expand past that for a horizontal display.
//
// To start (on mobile devices) the navbar links, forms, and buttons are stacked
// vertically and include a `max-height` to overflow in case you have too much
// content for the user's viewport.

.header-collapse {
    overflow-x:                 visible;
    padding-right:              @header-padding-horizontal;
    padding-left:               @header-padding-horizontal;
    border-top:                 1px solid transparent;
    box-shadow:                 inset 0 1px 0 rgba(255, 255, 255, .1);
    &:extend(.clearfix all);
    -webkit-overflow-scrolling: touch;

    &.in {
        overflow-y: auto;
    }

    @media (min-width: @grid-float-breakpoint) {
        width:      auto;
        border-top: 0;
        box-shadow: none;

        &.collapse {
            display:        block !important;
            height:         auto !important;
            padding-bottom: 0; // Override default setting
            overflow:       visible !important;
        }

        &.in {
            overflow-y: visible;
        }

        // Undo the collapse side padding for navbars with containers to ensure
        // alignment of right-aligned contents.
        .header-fixed-top &,
        .header-static-top & {
            padding-left:  0;
            padding-right: 0;
        }
    }
}

.header-fixed-top {
    .header-collapse {
        max-height: @header-collapse-max-height;

        @media (max-device-width: @screen-xs-min) and (orientation: landscape) {
            max-height: 200px;
        }
    }
}

// Both navbar header and collapse
//
// When a container is present, change the behavior of the header and collapse.

.container,
.container-fluid {
    > .header-uncollapseable,
    > .header-collapse {
        margin-right: -@header-padding-horizontal;
        margin-left:  -@header-padding-horizontal;

        @media (min-width: @grid-float-breakpoint) {
            margin-right: 0;
            margin-left:  0;
        }
    }
}

//
// Navbar alignment options
//
// Display the navbar across the entirety of the page or fixed it to the top or
// bottom of the page.

// Static top (unfixed, but 100% wide) navbar
.header-static-top {
    z-index:      @zindex-header;
    border-width: 0 0 1px;
}

// Fix the top/bottom navbars when screen real estate supports it
.header-fixed-top {
    position: fixed;
    right:    0;
    left:     0;
    top:          0;
    z-index:  @zindex-header-fixed;
}

//
// Brand
//
.header-brand {
    float:       left;
    padding:     20px @header-padding-horizontal;
    font-size:   @font-size-large;
    line-height: @line-height-computed;
    width:       (@header-brand-width + (@header-padding-horizontal * 2));
    height:      @header-brand-height;
    margin-right: @header-padding-horizontal;


    &:hover,
    &:focus {
        text-decoration: none;
    }

    > img {
        display: block;
        max-width: 100%;
        height: 38px;
    }

    @media (min-width: @grid-float-breakpoint) {
        .header > .container &,
        .header > .container-fluid & {
            margin-left: -@header-padding-horizontal;
        }
    }
}

@media (max-width: @screen-xs-max) {
    .header-brand {
      width: (@header-brand-width - 10%);
      height: (@header-brand-height - 10%);
      padding-top:25px;
      > img {
        height: (@header-brand-height - 10%);
      }
    }
}

// Navbar toggle
//
// Custom button for toggling the `.header-collapse`, powered by the collapse
// JavaScript plugin.

.header-toggle {
    position:         relative;
    float:            right;
    margin-right:     @header-padding-horizontal;
    padding:          9px 10px;
    .header-vertical-align(34px);
    background-color: transparent;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border:           1px solid transparent;
    border-radius:    @border-radius-base;

    // We remove the `outline` here, but later compensate by attaching `:hover`
    // styles to `:focus`.
    &:focus {
        outline: 0;
    }

    // Bars
    .icon-bar {
        display:       block;
        width:         22px;
        height:        2px;
        border-radius: 1px;
    }
    .icon-bar + .icon-bar {
        margin-top: 4px;
    }

    @media (min-width: @grid-float-breakpoint) {
        display: none;
    }
}

// Navbar nav links
//
// Builds on top of the `.nav` components with its own modifier class to make
// the nav the full height of the horizontal nav (above 768px).

.header-nav {
    margin: (@header-padding-vertical / 2) -@header-padding-horizontal;

    > li > a {
        padding-top:    10px;
        padding-bottom: 10px;
        line-height:    @line-height-computed;

    }

    @media (max-width: @grid-float-breakpoint-max) {
        // Dropdowns get custom display when collapsed
        .open .dropdown-menu {
            position:         static;
            float:            none;
            width:            auto;
            margin-top:       0;
            background-color: transparent;
            border:           0;
            box-shadow:       none;
            > li > a,
            .dropdown-header {
                padding: 5px 15px 5px 25px;
            }
            > li > a {
                line-height: @line-height-computed;
                &:hover,
                &:focus {
                    background-image: none;
                }
            }
        }
    }

    // Uncollapse the nav


        float:  left;
        margin: 0;
        > li {
            float: left;
            > a {
                padding: @header-padding-vertical 15px;
                > .glyphicons {
                    display: none;
                }
            }
        }
        &.header-nav-secondary {
            font-size: @font-size-base - 1;
            > li {

                > a {
                    padding-top:    @header-padding-vertical;
                    padding-bottom: @header-padding-vertical;
                    > .glyphicons {
                        display: inline-block;
                        font-size: 14px;
                        margin-right: 4px;
                        top: 3px;
                    }
                    > .header-nav-label {
                        display: none;
                    }
                }
            }
        }


        > li {
            > a {
                > .glyphicons {
                    display: inline-block;
                    font-size: 18px;
                    top: 1px;
                }
            }
        }
        &.header-nav-secondary {
            > li {
                > a {
                    > .header-nav-label-account {
                        display: inline;
                    }
                }
            }
        }

    @media(min-width: @screen-lg-min) {
        > li {
            > a {
                padding: @header-padding-vertical 20px;
            }
        }
        &.header-nav-secondary {
            > li {
                > a {
                    > .header-nav-label {
                        display: inline;
                    }
                }
            }
        }
    }
}


//
// Dropdown menus
//
// Menu position and menu carets
.header-nav > li > .dropdown-menu {
    margin-top: 0;
    .border-top-radius(0);
}


//
// Buttons in header
//
// Vertically center a button within a navbar
.header-btn {
    .header-vertical-align(@input-height-base);

    &.btn-sm {
        .header-vertical-align(@input-height-small);
    }
    &.btn-xs {
        .header-vertical-align(22);
    }
}

//
// Text in header
//
// Add a class to make any element properly align itself vertically within the header.
.header-text {
    .header-vertical-align(@line-height-computed);
    @media (min-width: @grid-float-breakpoint) {
        float:        left;
        margin-left:  @header-padding-horizontal;
        margin-right: @header-padding-horizontal;
    }
}

.welcome-wrapper {background: #262262; color: #FFF;}

.header-nav a {text-align: center;}
.header-nav a .glyphicons {font-size: 2.5em;}
.header-nav a .btn-label {display: block; padding-top: 10px; color: @loyally-light-blue}

.header-left {
    .pull-left();
}
.header-right {
    .pull-right();
    margin-right: -@header-padding-horizontal;

    ~ .header-right {
        margin-right: 0;
    }
}

//
// Component alignment
//
// Repurpose the pull utilities as their own header utilities to avoid specificity
// issues with parents and chaining. Only do this when the header is uncollapsed
// though so that header contents properly stack and align in mobile.
//
// Declared after the header components to ensure more specificity on the margins.
@media (min-width: @grid-float-breakpoint) {

}

//
// Colours
//
.header {
    background-color: @header-bg;
    border: 0;

    .header-brand {
        color: @header-brand-color;
        &:hover,
        &:focus {
            color:            @header-brand-hover-color;
            background-color: @header-brand-hover-bg;
        }
    }

    .header-text {
        color: @header-color;
    }

    .header-nav {
        > li > a {
            color: @header-link-color;

            &:hover,
            &:focus {
                color:            @header-link-hover-color;
                background-color: @header-link-hover-bg;
            }
        }
        > .active > a {
            &,
            &:hover,
            &:focus {
                color:            @header-link-active-color;
                background-color: @header-link-active-bg;
                .btn-label {
                  color:            @header-link-active-color;
                }
            }
        }
        > .disabled > a {
            &,
            &:hover,
            &:focus {
                color:            @header-link-disabled-color;
                background-color: @header-link-disabled-bg;
            }
        }
    }

    .header-toggle {
        border-color: @header-toggle-border-color;
        &:hover,
        &:focus {
            background-color: @header-toggle-hover-bg;
        }
        .icon-bar {
            background-color: @header-toggle-icon-bar-bg;
        }
    }

    .header-collapse,
    .header-form {
        border-color: @header-border;
    }

    // Dropdown menu items
    .header-nav {
        // Remove background color from open dropdown
        > .open > a {
            &,
            &:hover,
            &:focus {
                background-color: @header-link-active-bg;
                color:            @header-link-active-color;
            }
        }

        @media (max-width: @grid-float-breakpoint-max) {
            // Dropdowns get custom display when collapsed
            .open .dropdown-menu {
                > li > a {
                    color: @header-link-color;
                    &:hover,
                    &:focus {
                        color:            @header-link-hover-color;
                        background-color: @header-link-hover-bg;
                    }
                }
                > .active > a {
                    &,
                    &:hover,
                    &:focus {
                        color:            @header-link-active-color;
                        background-color: @header-link-active-bg;
                    }
                }
                > .disabled > a {
                    &,
                    &:hover,
                    &:focus {
                        color:            @header-link-disabled-color;
                        background-color: @header-link-disabled-bg;
                    }
                }
            }
        }
    }

    .header-link {
        color: @header-link-color;
        &:hover {
            color: @header-link-hover-color;
        }
    }

    .btn-link {
        color: @header-link-color;
        &:hover,
        &:focus {
            color: @header-link-hover-color;
        }
        &[disabled],
        fieldset[disabled] & {
            &:hover,
            &:focus {
                color: @header-link-disabled-color;
            }
        }
    }
}

.page-header {
  background: #FFF;
  margin: 0;
  padding: 30px 0;
  h1 {
    color: @loyally-blue;
    font-weight: 700;
    font-size: 2em;
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
}

.page-header + .strip-white {
  padding-top: 0;
}
